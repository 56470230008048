import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "incard-table-list"
};
var _hoisted_2 = {
  class: "incard-table-list__header"
};
var _hoisted_3 = {
  class: "incard-table-list__footer"
};
export function render(_ctx, _cache) {
  var _component_BaseTableList = _resolveComponent("BaseTableList");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "card-header")]), _createVNode(_component_BaseTableList, {
    cols: _ctx.$attrs.cols,
    list: _ctx.$attrs.list,
    class: "card-table-list"
  }, _createSlots({
    header: _withCtx(function (slotProp) {
      return [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps(slotProp)))];
    }),
    _: 2
  }, [_renderList(_ctx.$attrs.cols, function (col, idx) {
    return {
      name: col.slot,
      fn: _withCtx(function (slotProp) {
        return [_renderSlot(_ctx.$slots, col.slot, _normalizeProps(_guardReactiveProps(slotProp)))];
      })
    };
  })]), 1032, ["cols", "list"]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "card-footer")])]);
}