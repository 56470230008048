import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  var _component_BaseGrape = _resolveComponent("BaseGrape");

  return _openBlock(), _createBlock(_component_BaseGrape, {
    class: "divided-info-grap"
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  });
}